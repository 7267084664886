
import { defineComponent, reactive, onMounted, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import AxiosAdapter from '@/request/AxiosAdapter'
import HeaderTit from '@/components/HeaderTit.vue'
export default defineComponent({
  name: 'PaymentSuccess',
  components: { HeaderTit },
  setup() {
    const route = useRoute()
    //用户信息默认值
    const orderList: any = reactive({
      id: '',
      medCaseId: '',
      medCaseUserId: '',
      medCaseNumberWord: '',
      organizationName: '',
      mediatorName: '',
      mediationFee: 0.1,
      courtName: '',
      paymentName: '',
      paymentDate: '',
      payStatusName: '',
      billStatus: '',
      businessId: '',
      outTradeNo: '',
      appid: ''
    })

      const query = route.query.outTradeNo
      const params = {
        outTradeNo: query
      }
    const getList = async () => {
      const data: any = await AxiosAdapter.get({
        url: '/outInterface/getFinishPayInfo',
        data: params
      })
      orderList.id = data.id
      orderList.medCaseId = data.medCaseId
      orderList.medCaseUserId = data.medCaseUserId
      orderList.medCaseNumberWord = data.medCaseNumberWord
      orderList.organizationName = data.organizationName
      orderList.mediatorName = data.mediatorName
      orderList.mediationFee = data.mediationFee
      orderList.courtName = data.courtName
      orderList.paymentName = data.paymentName
      orderList.paymentDate = data.paymentDate
      orderList.payStatusName = data.payStatusName
      orderList.billStatus = data.billStatus
      orderList.businessId = data.businessId
      orderList.outTradeNo = data.outTradeNo
      orderList.appid = data.appid
    }
    onMounted(() => {
      getList()
    })
    return {
      orderList,
      getList
    }
  }
})
